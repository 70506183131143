<template>
  <BButton
    v-if="$can('update' , modulo)"
    variant="relief-primary"
    class="mr-0 btn-sm-block mt-25"
    @click="$emit('processBtn')"
  >
    {{ btnText }}
  </BButton>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    btnText: {
      type: String,
      required: true,
    },
    modulo: {
      type: String,
      required: true,
    },
  },
}
</script>
